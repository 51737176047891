<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <v-card>
      <v-card-title
        style="display:flex; flex-direction: row; justify-content:space-between;"
      >
        <span class="headline">Client Order Requests</span>
        <v-btn icon @click="onClose"><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>
      <v-card-text class="mt-3">
        <div
          v-for="request in requests"
          :key="request._id"
          class="d-flex flex-row mb-3 hover-item"
          @click="goToRequestDetails(request)"
        >
          <editable-avatar
            :image="request.client.image"
            :editable="false"
            :size="30"
            class="ml-3"
          />
          <div class="app-bold-font ml-5">
            {{ request.client.client_id }}
          </div>
          <div class="ml-5" style="width:150px">
            <div>
              {{ request.client.first_name }}, {{ request.client.last_name }}
            </div>
            <div class="font-12">
              {{ request.client.email }}
            </div>
            <div class="font-12">
              {{ formatPhoneNumber(request.client.phone) }}
            </div>
          </div>
          <div class="ml-5 mt-2 mr-5" style="width: 70px;">
            {{ getMealsCount(request.carts) }} meals
          </div>
          <div style="width: 100px" class="mt-2">
            {{ getTimeAgo(request.createdAt) }}
          </div>
          <div class="ml-5">
            <v-btn icon @click.stop="onRemoveRequest(request)"
              ><v-icon>mdi-delete</v-icon></v-btn
            >
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import EditableAvatar from "@/components/core/EditableAvatar.vue";

export default {
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    onClose: {
      type: Function,
    },
    onSelected: {
      type: Function,
    },
  },
  components: {
    EditableAvatar,
  },
  data() {
    return {};
  },
  methods: {
    ...mapActions("request", {
      fetchAllRequests: "fetchAllRequests",
      deleteRequest: "deleteRequest",
    }),
    getMealsCount(carts) {
      var total = 0;
      for (let i = 0; i < carts.length; i++) {
        total += carts[i].amount;
      }
      return total;
    },
    onRemoveRequest(request) {
      console.log(request);
      this.deleteRequest({ _id: request._id })
        .then((res) => {
          console.log(res);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    goToRequestDetails(request) {
      this.onSelected(request._id);
    },
    getTimeAgo(oldTimeStr) {
      const oldTime = Date.parse(oldTimeStr);
      const time = new Date();
      const different = (time.getTime() - oldTime) / 1000;
      const month = (different / 3600 / 24 / 30) | 0;
      const days = (different / 3600 / 24) % 30 | 0;
      const hours = (different / 3600) % 24 | 0;
      const mins = (different / 60) % 60 | 0;
      if (month == 1) return `1 month ago`;
      if (month) return `${month} months ago`;
      if (days == 1) return `1 day ago`;
      if (days) return `${days} days ago`;
      if (hours == 1) return `1 hours ago`;
      if (hours) return `${hours} hours ago`;
      if (mins == 1) return `1 min ago`;
      if (mins) return `${mins} min ago`;
      return `less than 1 min ago`;
    },
    formatPhoneNumber(str) {
      let cleaned = ("" + str).replace(/\D/g, "");
      let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        let intlCode = match[1] ? "+1 " : "";
        return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join(
          ""
        );
      }
      return str;
    },
  },
  computed: {
    ...mapGetters({
      requests: "request/getAllRequests",
    }),
  },
  mounted() {
    this.fetchAllRequests().catch((error) => {
      console.log(error);
    });
  },
};
</script>
<style>
.v-dialog::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.v-dialog::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px light-grey;
  border-radius: 3px;
}
/* Handle */
.v-dialog::-webkit-scrollbar-thumb {
  background: #f0a000;
  border-radius: 3px;
}

/* Handle on hover */
.v-dialog::-webkit-scrollbar-thumb:hover {
  background: #f0a000;
}
</style>
