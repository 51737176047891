<template>
  <v-dialog
    v-model="distributionDialog"
    persistent
    max-width="600px"
    @click:outside="onClickedBackground"
  >
    <v-card @click="onClickedBackground">
      <v-card-text
        class="pb-0 pt-8"
        style="align-items: center; display: flex; flex-direction: column;"
      >
        <v-icon size="60" color="green" class="mb-5"
          >mdi-alert-circle-outline</v-icon
        >
        <div class="font-20 text-center">
          This client is not eligible to be distribution. Do you want update the
          balance and distribute forced?
        </div>
      </v-card-text>
      <v-card-text>
        <v-text-field
          v-model="code"
          outlined
          autofocus
          rounded
          dense
          @keydown.enter="onEnterCode"
          @keydown.esc="onClear"
          ref="code"
          :error-messages="codeError"
          @focus="codeFocused = true"
          @blur="codeFocused = false"
          class="mt-5"
        />
      </v-card-text>
      <v-card-actions
        style="display: flex; flex-direction: row; justify-content:center;"
        class="pb-6"
      >
        <div @click.stop="onClose" class="mr-10">
          <barcode value="Close" :options="{ displayValue: true }" tag="img" />
        </div>
        <div @click.stop="onConfirm" class="ml-15">
          <barcode
            value="Confirm"
            :options="{ displayValue: true }"
            tag="img"
          />
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    distributionDialog: {
      type: Boolean,
      default: false,
    },
    onClose: {
      type: Function,
    },
    onConfirm: {
      type: Function,
    },
  },
  data() {
    return {
      code: "",
      codeError: null,
      focuses: [],
    };
  },
  methods: {
    onClickedBackground() {
      for (var i = 0; i < this.focuses.length; i++) {
        if (this.focuses[i]) return;
      }
      if (!this.codeFocused) this.onEnterCode();
    },
    onEnterCode() {
      this.error = null;
      this.codeError = null;
      if (this.code == "") {
        this.$refs.code.$refs.input.focus();
        return;
      } else if (this.code == "Cancel") this.onClose();
      else if (this.code == "Confirm") {
        this.code = "";
        this.onConfirm();
      } else {
        this.code = "";
        this.codeError = "Invalid code";
      }
      this.$refs.code.$refs.input.focus();
    },
  },
};
</script>
<style>
.v-dialog::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.v-dialog::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px light-grey;
  border-radius: 3px;
}
/* Handle */
.v-dialog::-webkit-scrollbar-thumb {
  background: #f0a000;
  border-radius: 3px;
}

/* Handle on hover */
.v-dialog::-webkit-scrollbar-thumb:hover {
  background: #f0a000;
}
</style>
