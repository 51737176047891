<template>
  <div class="d-flex freezer-home" @click="onClickedBackground">
    <div class="left-section">
      <v-card class="mt-5 ml-5 mb-3 mr-1">
        <v-card-title
          style="display:flex; flex-direction: row; justify-content:space-between;"
        >
          <span class="headline">Distribute</span>
          <div @click.stop="onClear">
            <barcode
              value="Clear"
              :options="{ displayValue: true }"
              tag="img"
            />
          </div>
        </v-card-title>
        <v-card-text
          class="pb-0"
          style="display: flex; flex-direction: column;"
        >
          <div class="d-flex flex-row mt-3" v-if="client">
            <editable-avatar
              :editable="false"
              :size="60"
              :image="client.image"
            />
            <div class="d-flex flex-column justify-center ml-3">
              <div style="font-weight: bold;">{{ client.client_id }}</div>
              <div style="font-weight: bold;">
                {{ client.first_name }} {{ client.last_name }}
              </div>
            </div>
          </div>
          <div v-if="rest_balance" class="mt-5 ml-3 mr-3 mb-3">
            <!-- <div
              v-if="rest_balance.meals"
              :style="
                rest_balance.meals < 0 ? 'color: red; font-weight: 800;' : ''
              "
            >
              Meals: {{ rest_balance.meals }}
            </div> -->
            <div
              v-if="rest_balance.fruits"
              :style="
                rest_balance.fruits < 0 ? 'color: red; font-weight: 800;' : ''
              "
            >
              Fruits: {{ rest_balance.fruits }}
            </div>
            <div
              v-if="rest_balance.vegetables"
              :style="
                rest_balance.vegetables < 0
                  ? 'color: red; font-weight: 800;'
                  : ''
              "
            >
              Vegetables: {{ rest_balance.vegetables }}
            </div>
            <div
              v-if="rest_balance.grains"
              :style="
                rest_balance.grains < 0 ? 'color: red; font-weight: 800;' : ''
              "
            >
              Grains: {{ rest_balance.grains }}
            </div>
            <div
              v-if="rest_balance.protein"
              :style="
                rest_balance.protein < 0 ? 'color: red; font-weight: 800;' : ''
              "
            >
              Protein:
              {{
                Math.round((rest_balance.protein + Number.EPSILON) * 100) / 100
              }}
            </div>
            <div
              v-if="rest_balance.dairy"
              :style="
                rest_balance.dairy < 0 ? 'color: red; font-weight: 800;' : ''
              "
            >
              Dairy: {{ rest_balance.dairy }}
            </div>
          </div>
        </v-card-text>
        <v-divider />
        <v-card-text class="mb-0 pb-0">
          <div v-if="!client">
            Scan the client's barcode or enter code manually.
          </div>
          <div v-else>
            Scan the food's barcode or enter code manually.
          </div>
          <v-text-field
            v-model="code"
            outlined
            autofocus
            rounded
            dense
            @keydown.enter="onEnterCode"
            @keydown.esc="onClear"
            ref="code"
            :error-messages="codeError"
            @focus="codeFocused = true"
            @blur="codeFocused = false"
          />
        </v-card-text>
      </v-card>
    </div>
    <div class="list-body">
      <v-card
        style="height: calc(100vh - 110px); display: flex; flex-direction: column;"
      >
        <v-card-text class="mt-2" style="overflow: auto; flex-grow: 1;">
          <div
            style="font-size: 18px; font-family: 'Poppins-SemiBold'; color: #423f63; margin-bottom: 10px;"
          >
            Food List
          </div>
          <div v-for="cart in carts" :key="cart.food._id">
            <div
              class="d-flex my-2"
              style="justify-content: space-between; align-items: center;"
            >
              <div>
                <v-img
                  :src="cart.food.image ? cart.food.image : placeholderImage"
                  :width="70"
                  :height="50"
                />
              </div>
              <div style="font-size: 18px; margin-left: 20px; width: 250px;">
                <div style="font-weight: bold;">{{ cart.food.name }}</div>
                <div style="font-size: 14px;">
                  {{ cart.food.serving_size }} {{ cart.food.unit }}
                </div>
                <div style="font-size: 12px;">
                  Number of serving: {{ cart.food.meals }}
                </div>
              </div>
              <div style="font-size: 16px; margin-left: 20px; width: 100px;">
                <div v-if="cart.food.fruits">
                  Fruits: {{ cart.food.fruits }}
                </div>
                <div v-if="cart.food.vegetables">
                  Vegetables: {{ cart.food.vegetables }}
                </div>
                <div v-if="cart.food.grains">
                  Grains: {{ cart.food.grains }}
                </div>
                <div v-if="cart.food.protein">
                  Protein: {{ cart.food.protein }}
                </div>
                <div v-if="cart.food.dairy">Dairy: {{ cart.food.dairy }}</div>
              </div>
              <div style="margin-left: 20px" v-if="cart.food.tags">
                <div v-for="tag in cart.food.tags" :key="tag" class="mb-1">
                  <v-chip :color="getColor(tag)">{{ tag }}</v-chip>
                </div>
              </div>
              <v-spacer />
              <div style="width: 100px">
                <v-text-field
                  v-model="cart.amount"
                  outlined
                  dense
                  type="number"
                  hide-details
                  @keydown.enter="onSwitchFocus(cart)"
                  @blur="onBlurFocus(cart)"
                  @focus="onFocus(cart)"
                />
              </div>
              <v-btn @click.stop="onDeleteItem(cart)" icon
                ><v-icon>mdi-close</v-icon></v-btn
              >
            </div>
          </div>
        </v-card-text>
        <v-alert dense type="error" v-if="error" class="mx-4">
          <span style="white-space: pre;">
            {{ error }}
          </span>
        </v-alert>
        <v-divider />
        <v-card-text class="mt-3 d-flex">
          <div
            v-if="
              summary.fruits ||
                summary.vegetables ||
                summary.grains ||
                summary.protein ||
                summary.dairy
            "
            style="flex-grow: 1"
          >
            <div
              style="font-size: 18px; font-family: 'Poppins-SemiBold'; color: #423f63; margin-bottom: 10px;"
            >
              Summary
            </div>
            <!-- <div v-if="summary.meals">
              Meals:
              {{ Math.round((summary.meals + Number.EPSILON) * 100) / 100 }}
            </div> -->
            <div v-if="summary.fruits">
              Fruits:
              {{ Math.round((summary.fruits + Number.EPSILON) * 100) / 100 }}
            </div>
            <div v-if="summary.vegetables">
              Vegetables:
              {{
                Math.round((summary.vegetables + Number.EPSILON) * 100) / 100
              }}
            </div>
            <div v-if="summary.grains">
              Grains:
              {{ Math.round((summary.grains + Number.EPSILON) * 100) / 100 }}
            </div>
            <div v-if="summary.protein">
              Protein:
              {{ Math.round((summary.protein + Number.EPSILON) * 100) / 100 }}
            </div>
            <div v-if="summary.dairy">
              Dairy:
              {{ Math.round((summary.dairy + Number.EPSILON) * 100) / 100 }}
            </div>
          </div>
          <div
            @click.stop="onDistribute"
            class="d-flex flex-column"
            style="justify-content: center; align-items: center;"
          >
            <barcode
              value="Distribute"
              :options="{ displayValue: false }"
              tag="svg"
            />
            <v-btn text @click.stop="onDistribute" :loading="loading"
              >Distribute</v-btn
            >
          </div>
        </v-card-text>
        <v-card-actions style="justify-content: center;"> </v-card-actions>
      </v-card>
    </div>
    <v-snackbar v-model="snackbar">
      {{ snackbarMessage }}
    </v-snackbar>
    <force-distribution-dialog
      :distributionDialog="distributionDialog"
      :onConfirm="onForceDistribute"
      :onClose="onCloseDialog"
      v-if="distributionDialog"
    />
    <client-order-requests-dialog
      :dialog="requestDialog"
      :onClose="onCloseDialog"
      :onSelected="onSelectedRequest"
      v-if="requestDialog"
    />
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import EditableAvatar from "@/components/core/EditableAvatar.vue";
import ForceDistributionDialog from "./ForceDistributionDialog.vue";
import ClientOrderRequestsDialog from "./ClientOrderRequestsDialog";
import io from "socket.io-client";
var socket = io.connect(process.env.VUE_APP_SOCKET_URL);

export default {
  components: {
    EditableAvatar,
    ForceDistributionDialog,
    ClientOrderRequestsDialog,
  },
  data() {
    return {
      rules: {
        required: (value) => !!value || "Required.",
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
        phone: (value) => {
          const pattern = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;
          return pattern.test(value) || "Invalid phone number.";
        },
        min: (v) => (v && v.length >= 8) || "Min 8 characters",
      },
      statusOptions: ["Yes", "No", "Urgent Help", "No response"],
      error: null,
      loading: false,
      placeholderImage: require("@/assets/default_image.png"),
      code: "",
      client: null,
      clientNotEligible: null,
      carts: [],
      codeError: null,
      summary: {},
      rest_balance: null,
      focuses: [],
      codeFocused: false,
      tags: [
        { title: "High fat", color: "red" },
        { title: "High sugar", color: "red" },
        { title: "Highly processed", color: "red" },
        { title: "Low nutritional value", color: "yellow" },
        { title: "Limit quantities", color: "yellow" },
        { title: "Healthy fats", color: "green" },
        { title: "Highly nutritional", color: "green" },
      ],
      distributionDialog: false,
      requestDialog: false,
      snackbar: false,
      snackbarMessage: null,
    };
  },
  methods: {
    ...mapActions({
      getClient: "client/getClient",
      distribute: "distribution/distribute",
      fetchAllFreezerBalances: "freezer/fetchAllFreezerBalances",
      fetchAllFoods: "food/fetchAllFoods",
      getFood: "food/getFood",
      getValues: "variable/getValues",
      getRequest: "request/getRequest",
      deleteRequest: "request/deleteRequest",
      fetchAllNotifications: "notification/fetchAllNotifications",
    }),
    onDistribute() {
      if (this.clientNotEligible) {
        this.distributionDialog = true;
        return;
      }
      if (!this.client || !this.carts.length || this.error || this.loading) {
        this.onEnterCode();
        return;
      }
      this.loading = true;
      var params = {
        client_id: this.client.client_id,
      };
      var cartsParam = [];
      for (var i = 0; i < this.carts.length; i++) {
        cartsParam.push({
          food_id: this.carts[i].food._id,
          amount: this.carts[i].amount,
        });
      }
      params.carts = cartsParam;

      this.distribute(params)
        .then(() => {
          this.error = null;
          this.loading = false;
          this.showSnackbar("Order processed successfully!");
          this.onClear();
        })
        .catch((error) => {
          this.loading = false;
          this.error = error.response.data.message;
        });
    },
    onForceDistribute() {
      this.distributionDialog = false;
      if (!this.client || !this.carts.length || this.loading) {
        this.onEnterCode();
        return;
      }
      this.loading = true;
      var params = {
        client_id: this.client.client_id,
        force: true,
      };
      var cartsParam = [];
      for (var i = 0; i < this.carts.length; i++) {
        cartsParam.push({
          food_id: this.carts[i].food._id,
          amount: this.carts[i].amount,
        });
      }
      params.carts = cartsParam;

      this.distribute(params)
        .then(() => {
          this.error = null;
          this.loading = false;
          this.showSnackbar("Order processed successfully!");
          this.onClear();
        })
        .catch((error) => {
          this.loading = false;
          this.error = error.response.data.message;
        });
    },
    onClear() {
      this.error = null;
      this.loading = false;
      this.code = "";
      this.client = null;
      this.carts = [];
      this.codeError = null;
      this.summary = {};
      this.rest_balance = null;
      this.loading = true;
      this.fetchAllFreezerBalances()
        .then(() => {
          this.loading = false;
        })
        .catch((error) => {
          console.log(error.response.data.message);
        });
      this.onEnterCode();
    },
    onChange() {
      this.error = null;
    },
    onCloseDialog() {
      this.distributionDialog = false;
      this.requestDialog = false;
    },
    onEnterCode() {
      this.error = null;
      this.codeError = null;
      if (this.code == "") {
        this.$refs.code.$refs.input.focus();
        this.checkValidCart();
        return;
      } else if (this.code == "Clear") this.onClear();
      else if (this.code == "Distribute") {
        this.code = "";
        this.onDistribute();
      } else if (this.code.length == 5 && this.code.match(/^[0-9]+$/) != null) {
        this.onSelectClient(this.code);
        this.code = "";
      } else if (
        this.code.length >= 10 &&
        this.code.length <= 14 &&
        this.code.match(/^[0-9]+$/) != null
      ) {
        this.onSelectFood(this.code);
        this.code = "";
      } else {
        this.code = "";
        this.codeError = "Invalid code";
      }
      this.$refs.code.$refs.input.focus();
      this.checkValidCart();
    },
    checkValidCart() {
      this.error = "";
      for (var i = 0; i < this.carts.length; i++) {
        if (parseInt(`${this.carts[i].amount}`) > this.carts[i].total) {
          this.error = "No enough item on the stock.";
          break;
        }
        if (
          this.carts[i].food.limit > 0 &&
          parseInt(`${this.carts[i].amount}`) > this.carts[i].food.limit
        ) {
          this.error = `${this.carts[i].food.name} can not take more than ${this.carts[i].food.limit} in a day.`;
          break;
        }
      }
      this.summary.meals = 0;
      this.summary.fruits = 0;
      this.summary.vegetables = 0;
      this.summary.grains = 0;
      this.summary.protein = 0;
      this.summary.dairy = 0;
      for (i = 0; i < this.carts.length; i++) {
        this.summary.meals += this.carts[i].amount * this.carts[i].food.meals;
        this.summary.fruits += this.carts[i].food.fruits * this.carts[i].amount;
        this.summary.vegetables +=
          this.carts[i].food.vegetables * this.carts[i].amount;
        this.summary.grains += this.carts[i].food.grains * this.carts[i].amount;
        this.summary.protein +=
          this.carts[i].food.protein * this.carts[i].amount;
        this.summary.dairy += this.carts[i].food.dairy * this.carts[i].amount;
      }
      if (!this.client) return;
      if (this.summary.fruits > this.client.rest_balance.fruits) {
        this.error +=
          "No enough eligible fruits: " +
          (this.client.rest_balance.fruits - this.summary.fruits) +
          "\n";
      }
      if (this.summary.vegetables > this.client.rest_balance.vegetables) {
        this.error +=
          "No enough eligible vegetables: " +
          (this.client.rest_balance.vegetables - this.summary.vegetables) +
          "\n";
      }
      if (this.summary.grains > this.client.rest_balance.grains) {
        this.error +=
          "No enough eligible grains: " +
          (this.client.rest_balance.grains - this.summary.grains) +
          "\n";
      }
      if (this.summary.protein > this.client.rest_balance.protein) {
        this.error +=
          "No enough eligible protein: " +
          (this.client.rest_balance.protein - this.summary.protein) +
          "\n";
      }
      if (this.summary.dairy > this.client.rest_balance.dairy) {
        this.error +=
          "No enough eligible dairy: " +
          (this.client.rest_balance.dairy - this.summary.dairy) +
          "\n";
      }
      if (this.error && this.error.substring(this.error.length - 1) == "\n")
        this.error = this.error.substring(0, this.error.length - 1);
      this.updateRestBalance();
    },
    updateRestBalance() {
      if (!this.client || !this.client.rest_balance) return;
      this.rest_balance.meals =
        this.client.rest_balance.meals - this.summary.meals;
      this.rest_balance.fruits =
        this.client.rest_balance.fruits - this.summary.fruits;
      this.rest_balance.vegetables =
        this.client.rest_balance.vegetables - this.summary.vegetables;
      this.rest_balance.grains =
        this.client.rest_balance.grains - this.summary.grains;
      this.rest_balance.protein =
        this.client.rest_balance.protein - this.summary.protein;
      this.rest_balance.dairy =
        this.client.rest_balance.dairy - this.summary.dairy;
    },
    onSelectClient(client_id) {
      this.getClient(client_id)
        .then((res) => {
          this.client = res;
          this.rest_balance = { ...this.client.rest_balance };
        })
        .catch((error) => {
          this.error = error.response.data.message;
          console.log(error.response.data.message);
        });
    },
    onSelectFood(food_barcode, food) {
      var ok = false;
      for (var i = 0; i < this.balances.length; i++) {
        if (
          this.balances[i].food.barcode == food_barcode ||
          this.balances[i].food.prod_barcode == food_barcode
        ) {
          ok = true;
          var isNew = true;
          for (var j = 0; j < this.carts.length; j++) {
            if (
              this.carts[j].food.barcode == food_barcode ||
              this.carts[j].food.prod_barcode == food_barcode
            ) {
              this.carts[j] = {
                food: this.balances[i].food ? this.balances[i].food : food,
                amount: parseInt(`${this.carts[j].amount}`) + 1,
                total: this.balances[i].amount,
              };
              isNew = false;
            }
          }
          if (isNew) {
            this.carts.push({
              food: this.balances[i].food ? this.balances[i].food : food,
              amount: 1,
              total: this.balances[i].amount,
            });
          }
        }
      }
      if (!ok) {
        this.error = "Food not found";
      }
    },
    onSwitchFocus(cart) {
      if (`${cart.amount}`.length > 10) {
        this.code = `${cart.amount}`.substr(`${cart.amount}`.length - 10);
        cart.amount = `${cart.amount}`.substr(0, `${cart.amount}`.length - 10);
        this.carts = this.carts.map((c) =>
          c.food._id == cart.food._id ? cart : c
        );
        this.onEnterCode();
      } else if (`${cart.amount}`.length > 5) {
        this.code = `${cart.amount}`.substr(`${cart.amount}`.length - 5);
        cart.amount = `${cart.amount}`.substr(0, `${cart.amount}`.length - 5);
        this.carts = this.carts.map((c) =>
          c.food._id == cart.food._id ? cart : c
        );
        this.onEnterCode();
      } else {
        this.onEnterCode();
      }
    },
    onFocus(cart) {
      for (var i = 0; i < this.carts.length; i++) {
        if (this.focuses.length <= i) this.focuses.push(false);
        if (this.carts[i].food._id == cart.food._id) {
          this.focuses[i] = true;
        }
      }
    },
    onBlurFocus(cart) {
      for (var i = 0; i < this.carts.length; i++) {
        if (this.focuses.length <= i) this.focuses.push(false);
        if (this.carts[i].food._id == cart.food._id) {
          this.focuses[i] = false;
        }
      }

      if (parseInt(`${cart.amount}`) <= 0) {
        this.carts = this.carts.filter((c) => c.food._id != cart.food._id);
      } else if (`${cart.amount}`.length > 10) {
        this.code = `${cart.amount}`.substr(`${cart.amount}`.length - 10);
        cart.amount = `${cart.amount}`.substr(0, `${cart.amount}`.length - 10);
        this.carts = this.carts.map((c) =>
          c.food._id == cart.food._id ? cart : c
        );
      } else if (`${cart.amount}`.length > 5) {
        this.code = `${cart.amount}`.substr(`${cart.amount}`.length - 5);
        cart.amount = `${cart.amount}`.substr(0, `${cart.amount}`.length - 5);
        this.carts = this.carts.map((c) =>
          c.food._id == cart.food._id ? cart : c
        );
      }

      for (i = 0; i < this.focuses.length; i++) {
        if (this.focuses[i]) return;
      }

      this.onEnterCode();
    },
    onDeleteItem(item) {
      for (var i = 0; i < this.carts.length; i++) {
        if (this.carts[i].food._id == item.food._id) {
          this.carts.splice(i, 1);
        }
      }
      this.onEnterCode();
    },
    onClickedBackground() {
      for (var i = 0; i < this.focuses.length; i++) {
        if (this.focuses[i]) return;
      }
      if (!this.codeFocused) this.onEnterCode();
    },
    getColor(tag) {
      for (var i = 0; i < this.tags.length; i++) {
        if (this.tags[i].title == tag) return this.tags[i].color;
      }
      return "light-grey";
    },
    showSnackbar(message) {
      this.snackbar = true;
      this.snackbarMessage = message;
    },
    onSelectedRequest(request) {
      this.requestDialog = false;
      this.$router.replace({ query: { request } });
    },
    setFoodAmount(food, amount) {
      this.carts = this.carts.map((cart) =>
        cart.food._id == food._id ? { ...cart, amount } : cart
      );
    },
  },
  computed: {
    ...mapGetters({
      balances: "freezer/getAllBalances",
      profile: "auth/getProfile",
    }),
  },
  watch: {
    rest_balance: {
      handler: function(newValue) {
        if (!newValue) this.clientNotEligible = false;
        else if (
          newValue.fruits < 0 ||
          newValue.vegetables < 0 ||
          newValue.grains < 0 ||
          newValue.protein < 0 ||
          newValue.dairy < 0
        ) {
          this.clientNotEligible = true;
        } else this.clientNotEligible = false;
      },
      deep: true,
    },
    "$route.query.request": function(newValue) {
      if (newValue) {
        console.log(newValue);
        this.getRequest({ _id: newValue })
          .then((res) => {
            if (res.client_id) {
              this.onSelectClient(res.client_id);
            }
            if (res.carts && res.carts.length) {
              for (let i = 0; i < res.carts.length; i++) {
                this.onSelectFood(res.carts[i].food.barcode, res.carts[i].food);
                this.setFoodAmount(res.carts[i].food, res.carts[i].amount);
              }
            }
            this.onEnterCode();
            this.deleteRequest({ _id: res._id });
          })
          .catch((error) => {
            console.log(error.response.data.message);
            this.$router.replace({ query: {} });
          });
      }
    },
    profile(newValue) {
      if (newValue._id) {
        socket.on(`client_request_${newValue._id}`, () => {
          this.fetchAllNotifications();
          this.requestDialog = true;
        });
      }
    },
  },
  mounted() {
    this.loading = true;
    this.fetchAllFreezerBalances()
      .then(() => {
        this.loading = false;
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });

    this.getValues({ title: "Food Tags" })
      .then((values) => {
        this.tags = values.map((value) => JSON.parse(value.text));
      })
      .catch((error) => {
        this.loading = false;
        if (error.response != undefined && error.response.status === 401) {
          this.tokenDialog = true;
        } else {
          console.log(error);
        }
      });

    if (this.$route.query.request) {
      this.getRequest({ _id: this.$route.query.request })
        .then((res) => {
          if (res.client_id) {
            this.onSelectClient(res.client_id);
          }
          if (res.carts && res.carts.length) {
            for (let i = 0; i < res.carts.length; i++) {
              this.onSelectFood(res.carts[i].food.barcode, res.carts[i].food);
              this.setFoodAmount(res.carts[i].food, res.carts[i].amount);
            }
          }
          this.onEnterCode();
          this.deleteRequest({ _id: res._id });
        })
        .catch((error) => {
          console.log(error);
          this.$router.replace({ query: {} });
        });
    }
    if (this.profile._id) {
      socket.on(`client_request_${this.profile._id}`, () => {
        this.fetchAllNotifications();
        this.requestDialog = true;
      });
    }
  },
};
</script>
<style>
.freezer-home {
  background-color: #f4f9ff;
  margin: 0;
  padding: 0;
  min-height: calc(100vh - 70px);
}
.list-body {
  width: 100%;
  min-width: 200px;
  margin-left: 10px;
  margin-right: 20px;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 20px;
}
.notranslate {
  transform: none !important;
}
.left-section {
  max-height: 0;
  overflow-y: hidden;
  position: sticky;
  min-height: inherit;
  overflow-x: hidden;
  top: 70px;
  max-width: 420px;
  min-width: 420px;
  width: 420px;
  background-color: #f4f9ff;
}
.left-section:hover {
  overflow-y: auto;
}
.left-section::-webkit-scrollbar {
  width: 8px;
  transition-duration: 0.5s;
}
</style>
